/** @format */
import "./index.scss";
import { partersList, partersList1 } from "@/contants";
import { useTranslation, Trans } from "react-i18next";
import LazyLoad from "react-lazyload";

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="fill-card" id="partners">
        <div
          className="card-title"
          style={{ textAlign: "center", marginBottom: 20 }}
          data-aos="fade-up"
        >
          <Trans i18nKey="partner.title">
            Our
            <span className="show-color linear">Partners</span>
          </Trans>
        </div>
        <div
          className="title-detail"
          style={{
            marginBottom: 80,
          }}
        >
          {t("partner.desc")}
        </div>
        <LazyLoad>
          <div className="partners">
            {partersList.map((item) => (
              <img
                src={item.logo}
                key={item.name}
                className={`icon_${item.name}`}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => {
                  item.url && window.open(item.url);
                }}
              />
            ))}
          </div>
        </LazyLoad>
      </div>

      <div className="fill-card" id="partners1">
        <div
          className="card-title"
          style={{ textAlign: "center", marginBottom: 60 }}
        >
          <Trans i18nKey="partner.title1">
            Media
            <span className="show-color linear">Partners</span>
          </Trans>
        </div>
        {/* <div
          className="title-detail"
          style={{
            marginBottom: 80,
          }}
        >
          {t("partner.desc")}
        </div> */}
        <LazyLoad>
          <div className="partners1">
            {partersList1.map((item) => (
              <div>
                <img
                  src={item.logo}
                  key={item.name}
                  className={`icon_${item.name}`}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    item.url && window.open(item.url);
                  }}
                />
              </div>
            ))}
          </div>
        </LazyLoad>
      </div>
    </>
  );
};
